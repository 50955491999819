import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { callIcon, chatIcon } from "../../blocks/RequestManagement/src/assets";

interface FooterProps {
  isSupportContent: boolean;
}

class Footer extends Component<FooterProps> {
  render() {
    const { isSupportContent } = this.props; 

    return (
      <div className="footer">
        <div className="card">
          <span className="title">
            {isSupportContent
              ? "Need something else?"
              : "Can’t find what you’re looking for?"}
          </span>
          <span className="desc pb-10">We’ll help!</span>
          <div className="btn-container">
            <Button
              className="supportbtn"
              href={`${window.location.origin}/ScheduleCall`}
              target="_blank"
            >
              <img src={callIcon} alt="Call Icon" /> &nbsp; Book a Call
            </Button>
            <Button
              className="supportbtn"
              onClick={() =>
                //@ts-ignore
                window.drift.api.openChat()
              }
            >
              <img src={chatIcon} alt="Chat Icon" /> &nbsp; Online Chat
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

