// Customizable Area Start
import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ClassboxesController, { Props } from "./ClassboxesController";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import "../assets/index.scss";
import { crossIcon, filterIcon } from "./assets";
import PopoverComponent from "./components/PopoverComponent";
import OrderModal from "./components/OrderModal";
import ClassBoxesCards from "./components/ClassBoxesCards";
import ClassBoxDetails from "./components/ClassBoxDetails";
import { setCurrentClassbox } from "../../../components/src/NativeWebRouteWrapper/classboxesSlice";
import NotificationCard from "./components/NotificationCard";

class Classboxes extends ClassboxesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container spacing={5} className="classboxes-container1" style={Object.keys(this.props?.classBoxesReduxData?.currentClassboxData || {})
      .length > 0 ? {backgroundColor: "white"} : {}}>
        <Grid item container sm={7} className="left-container padding-card-cs">
          <Grid item sm={12} className="header">
            <span className="title">📦 Supplies</span>
          </Grid>
          <NotificationCard
            state={this.state}
            navigation={this.props.navigation}
            handleCheckOut={this.handleCheckOut}
          />
          <div className="relative-grid-class">
            {this.state.openFilter && (
              <PopoverComponent
                handleCloseModal={this.handleCloseModal}
                state={this.state}
                handletab1Checkbox={this.handletab1Checkbox}
                handletab0Checkbox={this.handletab0Checkbox}
                handleChangeV1={this.handleChangeV1}
                handleChangeV2={this.handleChangeV2}
                handleCompareChange={this.handleCompareChange}
                handleTabsButton={this.handleTabsButton}
                handleChangeTab={this.handleChangeTab}
              />
            )}
            <Grid item sm={12} className="filter-container">
              <div className="filter pointer" onClick={this.setFilterOpen}>
                <img src={filterIcon} alt="icon" />
                &nbsp;<span className="title">Classbox Filters</span>
              </div>

              <>
                <Divider orientation="vertical" />
                <div>
                  {this.state.filterSelected ? (
                    <span className="title1">Applied filters:</span>
                  ) : (
                    <span className="title1">No filters applied</span>
                  )}
                </div>
              </>
            </Grid>
            {(this.state.tab0Show.length > 0 ||
              this.state.tab2show !== "" ||
              this.state.tab1show.length > 0) && (
              <Grid
                item
                container
                sm={12}
                className="filters-collection"
                spacing={3}
              >
                {this.state.tab0Show.length > 0 && (
                  <Grid item>
                    <div className="filter-container1">
                      <span className="txt">
                        Order Options: {this.state.tab0Show.join(", ")}{" "}
                        &nbsp;&nbsp;{" "}
                        <img
                          className="cross-icon"
                          src={crossIcon}
                          alt="cancle"
                          onClick={this.clearFilterZero}
                        />
                      </span>
                    </div>
                  </Grid>
                )}
                {this.state.tab2show !== "" && (
                  <Grid item>
                    <div className="filter-container1">
                      <span className="txt">
                        Credit Cost: {this.state.tab2show} &nbsp;&nbsp;{" "}
                        <img
                          src={crossIcon}
                          className="cross-icon"
                          alt="cancle"
                          onClick={this.clearFilterTwo}
                        />
                      </span>
                    </div>
                  </Grid>
                )}
                {this.state.tab1show.length > 0 && (
                  <Grid item>
                    <div className="filter-container1">
                      <span className="txt">
                        Classbox Type: {this.state.tab1show.join(", ")}{" "}
                        &nbsp;&nbsp;{" "}
                        <img
                          className="cross-icon"
                          src={crossIcon}
                          alt="cancle"
                          onClick={this.clearFilterOne}
                        />
                      </span>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            <ClassBoxesCards
              state={this.state}
              setClassBoxDetails={this.setClassBoxDetails}
              itemId={this.props.classBoxesReduxData.currentClassboxData?.id}
            />
          </div>
        </Grid>
        {Object.keys(this.props?.classBoxesReduxData?.currentClassboxData || {})
          .length > 0 && (
          <ClassBoxDetails
            state={this.state}
            propsdata={this.props?.classBoxesReduxData}
            navigation={this.props.navigation}
            handleCheckOut={this.handleCheckOut}
          />
        )}
        {this.state.selectedCheckoutCard?.classbox_parts?.data && (
          <OrderModal
            handleClose={this.handleOrderModal}
            handleModalTabChange={this.handleModalTabChange}
            state={this.state}
            handleReturnClassBox={this.handleReturnClassBox}
            handleKeepClassBox={this.handleKeepClassBox}
            handleExtendClassBox={this.handleExtendClassBox}
            requestCreditsForTraining={this.requestCreditsForTraining}
          />
        )}
        <LoaderComponent open={this.state.loading} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
      </Grid>
      // Customizable Area End
    );
  }
}

export const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentClassbox: (data: any) => {
      dispatch(setCurrentClassbox(data));
    },
  };
};
export const mapStateToProps = (state: any) => {
  return {
    classBoxesReduxData: state.classboxesSlice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Classboxes);
// Customizable Area End
