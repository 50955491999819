// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getUserDetails,
  getnusedcredits,
  handleTokenError,
  isTeacher,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import { DrawerDataTypes } from "../../../components/src/TableComponent";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setTeacherPendingRequest: (data:Object)=>void;
  // Customizable Area End
}

type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  open: boolean;
  dialogOpen: boolean;
  isDrawerOpen: boolean;
  leadMail: string;
  openReclaimModal: boolean;
  openAddCreditModal: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  mySchoolDetails: any;
  teachersData: any;
  drawerDetails: any;
  teacherTransactionList: any[];
  page: number,
  perPage: number,
  pagination: any,
  staffColeadsUpdateApiErrors : boolean,
  pendingCheckoutPopup: boolean,
  refreshUserDetails:boolean,
  isCurrentUserRoleUpdate:boolean,
  teacherRequestData: any[];
  pendingCheckoutData : {
    recordId:string,
    classboxName: string;
    returnDate: string;
  }[],
  pendingCheckMetaDataCatch : {
    errors: string,
    user_name: string,
    school: string
  }
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class MySchoolController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  submitApiCallId: any;
  getMySchoolDetailApiId: any;
  getPaymentsHistoryApiId: any;
  inviteTeacherApiId: any;
  creditAddRemoveApiid: any;
  staffCoLeadUpdateApiId : string = "";
  staffDeleteApiId:string="";
  leadDeleteApiId:string="";
  getUserDetailsApiId:string="";
  getTeacherPendingRequest:string="";
  aprroveTeacherRequest:string="";
  rejectTeacherRequest:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      teacherRequestData: [],
      open: false,
      dialogOpen: false,
      leadMail: "",
      isDrawerOpen: false,
      openReclaimModal: false,
      openAddCreditModal: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      mySchoolDetails: {},
      teachersData: [],
      drawerDetails: {},
      teacherTransactionList: [],
      page: 1,
      perPage: 8,
      pagination: {},
      staffColeadsUpdateApiErrors : false,
      pendingCheckoutPopup: false,
      refreshUserDetails:false,
      isCurrentUserRoleUpdate:false,
      pendingCheckoutData : [{
        recordId: "",
        classboxName: "",
        returnDate: ""
      }],
      pendingCheckMetaDataCatch : {
        errors: "",
        user_name: "",
        school: ""
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    if (isTeacher()) {
      this.props.navigation.goBack()
    }
    this.setLeadMail();
    this.getMySchoolDetail();
    this.getTeacherPendingRequestData();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if(prevState.refreshUserDetails!=this.state.refreshUserDetails){
      if(this.state.refreshUserDetails){
        const userId= getUserDetails()?.id
      this.fetchUserDetails(userId);
      }
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });

        if (!responseJson.errors) {
          if (apiRequestCallId === this.getMySchoolDetailApiId) {
            this.onRecieveMySchoolDetailsResponse(responseJson)
          } else if (apiRequestCallId === this.getPaymentsHistoryApiId) {
            this.setState({
              pagination: responseJson.meta.pagination,
              teacherTransactionList: [...this.state.teacherTransactionList, ...responseJson.history],
            });
          } else if (apiRequestCallId === this.inviteTeacherApiId) {
            this.getMySchoolDetail();
            this.setState({
              showSnackbar: true,
              severity: "success",
              message:
                responseJson?.message || "Teacher is invited successfully",
            });
          } else if (apiRequestCallId === this.creditAddRemoveApiid) {
            this.getMySchoolDetail();
            this.setState({
              openReclaimModal: false,
              openAddCreditModal: false,
            });
            this.handleDrawer();
            this.setState({
              showSnackbar: true,
              severity: "success",
              message:
                responseJson?.response_message || "Credit updated successfully",
            });
          }else if(apiRequestCallId===this.getUserDetailsApiId){            
           this.handleGetUserResponse(responseJson)
          }
          if(apiRequestCallId===this.getTeacherPendingRequest){
            const RequestTeacherData = responseJson.data;
            let count = RequestTeacherData.length;      
            this.setTeacherPendingRequest(count);
          this.setState({
            teacherRequestData: RequestTeacherData
          });
          }
          if(apiRequestCallId===this.aprroveTeacherRequest){
            const approvedata=responseJson.message;
            console.log(approvedata,"approvedata");
            if(approvedata){
            
                window.location.reload();
             
            }
          }
          if(apiRequestCallId===this.rejectTeacherRequest){
            const rejectdata=responseJson.message;
            window.location.reload();
          }
        }
      }
      this.onRecieveStaffCoLeadUpdateResponse(responseJson, apiRequestCallId)
      this.onRecieveStaffDeleteResponse(responseJson, apiRequestCallId)
    }
    // Customizable Area End
  }

  fetchMoreData = () => {
    this.setState({
      page: this.state.page + 1
    }, () => this.getPaymentHistoryApi(this.state.drawerDetails?.teacherId))
  }


  // on recieve refactored functions

  onRecieveMySchoolDetailsResponse = (responseJson : {teacher_credit_details : [], school_credit_details : {data: {}}}) => {
    let teachersData: {teacherId : number,name: string, permission: string, activated : boolean, image: string, earned : number, allocated : number, currentBalance:number, unused_earned_credits : number, currentUser : boolean, schoolName:string}[] = [];
    
    responseJson.teacher_credit_details.map(
      (teacher: {id : number,name: string, current_user : boolean, school_name : string, role: string, activated : boolean, image: {url: string}, credit_data : {data: {attributes : {reward : number, recieved : number, current_balance : number,unused_earned_credits:number,unused_allocated_credits:number}}}}) => {
        teachersData.push({
          name: teacher.name,
          permission: teacher?.role,
          earned: teacher.credit_data.data?.attributes?.unused_earned_credits	,
          allocated:
            teacher.credit_data.data?.attributes?.unused_allocated_credits,
          currentBalance:
            teacher?.credit_data?.data?.attributes?.current_balance,
          activated: teacher.activated,
          teacherId: teacher.id,
          image: teacher?.image?.url,
          unused_earned_credits:getnusedcredits(teacher),
          currentUser : teacher.current_user,
          schoolName: teacher.school_name,
        });
        return teacher;
      }
    );
    let getData = JSON.parse(localStorage.getItem("userInfo") || "");
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        ...getData,
        school: {
          ...getData.school.data,
          data: {
            ...getData.school.data, attributes: {
              ...getData.school.data.attributes, school_credits: {
                data: [{ ...responseJson.school_credit_details.data }]
              }
            }
          },
        },
      })
    );
    window.dispatchEvent(new Event("storage"));
    this.setState({
      mySchoolDetails: responseJson,
      teachersData: teachersData,
    });
  }

  onRecieveStaffCoLeadUpdateResponse = (responseJson : {message : string, error: string, errors : string}, apiRequestCallId : string) => {
    if(apiRequestCallId === this.staffCoLeadUpdateApiId){
      if(responseJson){
        if(!responseJson.errors){
          this.setState({
            severity: "success",
            showSnackbar: true,
            message:responseJson.message,
          });
          this.getMySchoolDetail();
        }
        else if(responseJson.errors){
          const errorMsg = "First, assign a new Lead"
          if(responseJson.errors.toLowerCase() === errorMsg.toLowerCase()){
            this.setState({
              staffColeadsUpdateApiErrors : true
            })
          } else {
            this.setState({
              severity: "error",
              showSnackbar: true,
              message:responseJson.errors,
            });
          }
        }
      }
    }
  }
  onRecieveStaffDeleteResponse = (responseJson : {message : string, error: string, errors : string , meta : {errors: string, user_name : string, school : string}, data: []}, apiRequestCallId : string) => {
    if(apiRequestCallId === this.staffDeleteApiId || apiRequestCallId === this.leadDeleteApiId){
      if(responseJson){
        if(this.state.isCurrentUserRoleUpdate){
          this.setState({refreshUserDetails:true})
        }
        this.onDataGet(responseJson)
      }
    }
  }
  handleGetUserResponse = (responseJson: { data: { attributes: unknown; }; })=>{
    if (responseJson.data.attributes) {
      let userInfo =  responseJson?.data?.attributes;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      this.setState({refreshUserDetails:false,isCurrentUserRoleUpdate:false})
      this.handleredirection("Dashboard")
      window.dispatchEvent(new Event("storage"));
    }
  }
  onDataGet=(responseJson:{message : string, error: string, errors : string , meta : {errors: string, user_name : string, school : string}, data: []})=>{
    const deleteErrorMsg = "First, assign a new Lead"
    const pendingCheckErrMsg = "Complete pending Checkout"
    if(!responseJson.errors && !responseJson?.meta?.errors){
      this.getMySchoolDetail();
      this.setState({
        showSnackbar: true,
        severity: "success",
        message:responseJson.message || "Teacher Removed Successfully",
      });
    }
    else if(responseJson.errors){
      if(responseJson.errors.toLowerCase() === deleteErrorMsg.toLowerCase()){
        this.setState({
          staffColeadsUpdateApiErrors : true
        })
      } 
      else {
        this.setState({
          message:responseJson.errors,
          showSnackbar: true,
          severity: "error",
        });
      }
    }
    else if(responseJson.meta.errors && responseJson.meta.errors.toLowerCase() === pendingCheckErrMsg.toLowerCase()){
      const rectifiedData = responseJson.data?.map((item: {
        id:string,
        attributes: {
          classbox_name: string;
          end_date: string;
        };
      }) => {
        return {
          recordId:item.id,
          classboxName: item.attributes.classbox_name,
          returnDate: item.attributes.end_date,
        };
      });
      this.setState({
        pendingCheckoutData: rectifiedData,
        pendingCheckMetaDataCatch: responseJson.meta,
        pendingCheckoutPopup : true,
      })
    }
  }
  getPaymentHistoryApi = (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPaymentsHistoryApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPaymentsHistoryApiEndPoint}${id}&page=${this.state.page}&per_page=${this.state.perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };

  getMySchoolDetail = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMySchoolDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMySchoolDetailApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTeacher = (value: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };
    this.handleDialog();
    let datas = {
      data: {
        type: "email_account",
        attributes: {
          first_name: value.firstName,
          last_name: value.lastName,
          email: value.email,
          role_name: "Teacher",
          admin_create: true,
          email_invitation: true,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.inviteTeacherApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteTeacherApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  setDrawerData = (value: DrawerDataTypes) => {
    this.setState({
      drawerDetails: value,
    });
    this.getPaymentHistoryApi(value?.teacherId);
    return true;
  };

  setLeadMail = () => {
    let userMail = getUserDetails()?.email || "";
    let domain = userMail.replace(configJSON.regx, "");
    this.setState({ leadMail: domain });
    return true;
  };

  handleDialog = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
    return true;
  };

  handleDrawer = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
      page: 1,
      teacherTransactionList: []
    });
    return true;
  };

  handleReclaimModal = () => {
    this.setState({
      openReclaimModal: !this.state.openReclaimModal,
    });
    return true;
  };

  handleAddCreditModal = () => {
    this.setState({
      openAddCreditModal: !this.state.openAddCreditModal,
    });
    return true;
  };

  onClickReclaimCredit = (value: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    let datas = {
      data: {
        reason: value.reason,
      },
    };

    this.handleReclaimModal();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.creditAddRemoveApiid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCreditsApiEndPoint}teacher_id=${this.state.drawerDetails.teacherId}&value=${value.reclaimCredit}&credit_type=reclaimed`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddCredit = (value: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    let datas = {
      data: {},
    };

    this.handleAddCreditModal();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.creditAddRemoveApiid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCreditsApiEndPoint}teacher_id=${this.state.drawerDetails.teacherId}&value=${value.AllocateCredit}&credit_type=allocated`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  onMakeCoLeadAction = (catchedId: number, catchedRole: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.staffCoLeadUpdateApiId = requestMessage.messageId;

    const exactRoleToSend = catchedRole === "Lead" ? "Teacher" : "Lead"

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCoLeadsApiEndPoint}role=${exactRoleToSend}&id=${catchedId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleApiPUTMethodType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseErrDialog = () => {
    this.setState({
      staffColeadsUpdateApiErrors : false
    })
  }

  handlePendingCheckoutPopupClose= () => {
    this.setState({pendingCheckoutPopup : false})
  }

  setTeacherPendingRequest = (data: any) => {
    this.props.setTeacherPendingRequest(data);
  };

  getTeacherPendingRequestData = () => {
    this.setState({ loading: true });
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": configJSON.teacherRequestContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeacherPendingRequest = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teacherRequestEndPonit}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.teacherRequestMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  approveTeacherRequestData = (id: string) => {
    this.setState({ loading: true });
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": configJSON.approveteacherContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.aprroveTeacherRequest = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.approveteacherEndPonit.replace(':id', id)}?approved=true`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.approveteacherMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    this.getTeacherPendingRequestData();
    
    return true;
  };
  rejectTeacherRequestData = (id: string) => {
    this.setState({ loading: true });
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": configJSON.rejectteacherContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.rejectTeacherRequest = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rejectteacherEndPonit.replace(':id', id)}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.rejectteacherMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    this.getTeacherPendingRequestData();
    
    return true;
  };

  onStaffDeleteAction = (catchedId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.staffDeleteApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteStaffApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleApiDeleteMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({"id": catchedId})
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onLeadDeleteAction = (catchedId: number,currentUser?:boolean) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };
    if(currentUser){
      this.setState({isCurrentUserRoleUpdate:true})
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.leadDeleteApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteLeadApiEndPoint}${catchedId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleApiPUTMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchUserDetails = (userId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDetailsApiEndPoint}${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleApiPUTMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


}
// Customizable Area End

// Customizable Area End
