// Customizable Area Start
import React from "react";
export const configJSON = require("./config");
import { Breadcrumbs, Typography, Grid, Button } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SupportController, { Blogs, Props } from "./SupportController";
import "../assets/support.scss";
import { callIcon, chatIcon } from "./assets";
import Footer from "../../../components/src/Supportfooter";



export default class SupportContent extends SupportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const indexNumber = localStorage.getItem("articalIndex");
    const indexContentNumber = localStorage.getItem("articalContentIndex");
    const filterData = indexNumber !== undefined
    ? this.state.blogs[Number(indexNumber)]
    : undefined;
  const sortedBlogs = filterData?.blogs?.slice().sort((a, b) =>
    a.title.localeCompare(b.title)
  );
  const filterDataContent = sortedBlogs && indexContentNumber !== undefined
    ? sortedBlogs[Number(indexContentNumber)]
    : undefined;
    return (
        <React.Fragment>
        <div className="articalMain">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography className="supportbread" onClick={() => this.handleNavigation("Support")}>
            Support
          </Typography>
          ,
          <Typography className="supportbread" onClick={()=>this.handleNavigation("Artical")}>
            {filterData?.title}
          </Typography>,
          <Typography className="supportartical"
          >
            {filterDataContent?.title}
          </Typography>
          filter
        </Breadcrumbs>
        { filterDataContent &&
            <Grid className="contentsupprot" sm={12}>
            <div className="contentTitle">{filterDataContent.title}</div>
            <div dangerouslySetInnerHTML={{ __html: filterDataContent.content }} />
           </Grid>
        }

        </div>
        <Footer isSupportContent={true}/>
      </React.Fragment>
      
    );
  }
}
// Customizable Area End

