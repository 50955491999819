// Customizable Area Start
import React from "react";
// Customizable Area Start
import DashBoardWebController, { Props } from "./DashBoardWebController";
import { TeacherDashBoardMain } from "./components/TeacherDashBoardMain";
import LeadDashboardMain from "./components/LeadDashboardMain";
import "../assets/lead-dasboard.scss";
import "../assets/teacher-dashboard.scss";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import { setPendingRequest } from "../../../components/src/NativeWebRouteWrapper/pendingRequestSlice";
import { setTeacherPendingRequest } from "../../../components/src/NativeWebRouteWrapper/teacherPendingRequest";
import { connect } from "react-redux";
// Customizable Area End

export class DashBoard extends DashBoardWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // this.props.navigation?.getParam("isFromSignup")
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    const isFirstTeacher = localStorage.getItem("isFirstTeacher");
    const isFirstLead = localStorage.getItem("isFirstLead");
    // isFirstTeacher === "true" ? true : false
    return !this.state.IsTeacher ? (
      <>
        <LeadDashboardMain
          isFirstTime={isFirstLead === "true" ? true : false}
          state={this.state}
          handleClose={this.closeVideoModal}
          navigation={this.props.navigation}
          fetchMoreData={this.fetchMoreData}
        />
        <LoaderComponent open={this.state.loading} />
      </>
    ) : (
      <>
        <TeacherDashBoardMain
          handleCheckOut={this.handleCheckOut}
          handleClose2={this.handleOrderModal}
          handleModalTabChange={this.handleModalTabChange}
          navigation={this.props.navigation}
          isFirstTime={isFirstTeacher === "true" ? true : false}
          state={this.state}
          handleClose={this.closeVideoModal}
          showTitle={this.showTitle}
          startStopProject={this.startStopProject}
          deleteProject={this.deleteProject}
          handleDialouge={this.handleDialouge}
          handleReturnClassBox={this.handleReturnClassBox}
          handleKeepClassBox={this.handleKeepClassBox}
          handleExtendClassBox={this.handleExtendClassBox}
          requestCreditsForTraining={this.requestCreditsForTraining}
          props={this.props}
        />
        <LoaderComponent open={this.state.loading} />
      </>
    );
    // Customizable Area End
  }
}
export const mapDispatchToProps = (dispatch: any) => {
  return {
    setPendingRequest: (data: any) => {
      dispatch(setPendingRequest(data));
    },
    setTeacherPendingRequest: (data: any) => {
      dispatch(setTeacherPendingRequest(data));
    }
  };
};
export const mapStateToProps = (state: any) => {
  return {
    pendingRequest: state?.pendingRequestData?.value,
    teacherPendingRequest: state?.teacherPendingRequestData?.value
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);

// Customizable Area End
