Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SalesReporting";
exports.labelBodyText = "SalesReporting Body";

exports.btnExampleTitle = "CLICK ME";

exports.overviewTitle = "Overview";
exports.salesByUnitTitle = "Sales";
exports.salesSumTitle = "Sales (Cum)";
exports.revenueBreakdownTitle = "Revenue Breakdown";
exports.comparisonTitle = "Sales Comparison"
exports.overviewUnit = "Unit";
exports.overviewRevenue = "Revenue";

exports.sampleReporting = {
  "overview": {
    "unit": 7,
    "revenue": 35073
  },
  "sales": {},
  "revenueBreakdown": {
    "Laundry": 100,
    "Dry Cleaning": 200,
    "Pressing": 300,
    "Blankets": 400
  },
  "currency": "$",
}

exports.periods = [{ pkey: 'hour', value: 'Today' }, { pkey: 'day', value: 'This Week' }, { pkey: 'week', value: 'This Month' }, { pkey: 'month', value: 'This Year' }];
exports.chartColors = ['#fff100', '#ff8c00', '#e81123', '#ec008c', '#68217a', '#00188f', '#00bcf2', '#00b294', '#009e49', '#bad80a'];
exports.chartLabels = {
  'hour': ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
  'day': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  'week': [],
  'month': ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
}

exports.webSalesChartDataLabels = ["Time", "Sales"];
exports.webRevenueBreakdownLabels = ["Key", "Value"];

exports.webSalesChartOptions = {
  curveType: "function",
  legend: { position: "bottom" },
};

exports.httpGetMethod = 'GET'
exports.getSalesReportApiContentType = 'application/json'
exports.getSalesReportApiEndPoint = 'bx_block_salesreporting/sales_report'
exports.errorTitle = 'Error'
exports.noDataText = 'No Data';
exports.orderDataEndPoint= "classbox/order_details/vendor_orders";
exports.orderAnalyticsEndPoint="classbox/order_details/order_analytics";
exports.httpPatchMethod="PATCH";;
exports.filterDataContentType = 'application/json';
exports.filterDataMethod='GET';
exports.filterEndPonit='classbox/order_details/listing_filters';
exports.wrapDataContentType = 'application/json';
exports.wrapDataMethod='GET';
exports.wrapEndPonit='classbox/order_details/:id/vendor_order';
exports.updatewrapDataContentType = 'application/json';
exports.upadtewrapDataMethod='PATCH';

exports.updatewrapEndPoint='classbox/order_details/:id/update_order';
exports.shipByDateEndPoint='classbox/order_details/next_ship_date_orders?next_ship_date=';

// Customizable Area End