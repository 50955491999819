import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../blocks/salesreporting/assets/vendorOrders.scss";
//@ts-ignore
import { ReactComponent as CalendarIcon } from "./calendar1.svg";

interface FormValues {
  latest_expected_delivery_date: string | Date | null;
}

interface CalendarComponentProps {
  shipByDate: string|null; 
  isShipped:boolean;
}

const CalendarLatest: React.FC<CalendarComponentProps> = ({ shipByDate,isShipped}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();


  const [defaultDate, setDefaultDate] = useState<Date | undefined>(
    shipByDate ? new Date(shipByDate) : new Date()
  );

  const selectedDate = values.latest_expected_delivery_date 
    ? new Date(values.latest_expected_delivery_date) 
    : undefined;


  useEffect(() => {
    if (shipByDate) {
      setDefaultDate(new Date(shipByDate));
    }
  }, [shipByDate]);

  return (
    <div className="calendar-container">
      <div className="date-picker-wrapper">
        <DatePicker
          selected={selectedDate} 
          onChange={(date: Date) => setFieldValue("latest_expected_delivery_date", date.toISOString())}
          dateFormat="MM/dd/yyyy"
          placeholderText="MM/DD/YYYY"
          className="custom-date-input"
          calendarClassName="custom-calendar"
          minDate={shipByDate ? new Date(shipByDate) : new Date()}
          onKeyDown={(e) => e.preventDefault()}
          openToDate={defaultDate}
          disabled={!isShipped}
        />
        <span className="calendar-icon">
          <CalendarIcon width="18" height="20" />
        </span>
      </div>
    </div>
  );
};

export default CalendarLatest;