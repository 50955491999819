import * as Yup from 'yup';
export const validationSchema = Yup.object({
    earliest_expected_delivery_date: Yup.date()
    .nullable()
    .when("status", {
      is: "shipped",
      then: (schema: Yup.DateSchema<Date | null>) => schema.required("Earliest Expected Delivery Date is required"),
      otherwise: (schema: Yup.DateSchema<Date | null>) => schema.notRequired(),
    }),
    latest_expected_delivery_date: Yup.date()
    .nullable()
    .when("status", {
      is: "shipped",
      then: (schema: Yup.DateSchema<Date | null>) =>
        schema
          .required("Latest Expected Delivery Date is required")
          .test(
            "is-after-earliest",
            "Latest Expected Delivery Date must be after or equal to Earliest Expected Delivery Date",
            function (value) {
              const { earliest_expected_delivery_date } = this.parent;
              return (
                !earliest_expected_delivery_date || 
                !value || 
                value >= earliest_expected_delivery_date
              );
            }
          ),
      otherwise: (schema: Yup.DateSchema<Date | null>) => schema.notRequired(),
    }),
        tracking_url: Yup.string()
        .url('Must be a valid URL')
        .when('status', {
          is: 'shipped',
          then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Tracking URL is required'),
          otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
        }),
  });