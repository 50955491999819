// Customizable Area Start
import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import SupportController, { Blogs, Content, Props } from "./SupportController";
import "../assets/support.scss";
import { backIcon, callIcon, chatIcon, background } from "./assets";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import { scrollToTop } from "../../../components/src/NativeWebRouteWrapper/Utils";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Footer from "../../../components/src/Supportfooter";


export default class Support extends SupportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container item className="support-container">
        {!this.state.data && (
          <Grid container item spacing={5} className="common-header" id="getBackxyz1297">
            <Grid sm={12} item className="title">
              <span>🙋 Support</span>&nbsp;
            </Grid>
          </Grid>
        )}

        <div className={!this.state.data ? "suport-content" : "suport-content1"}>
          {!this.state.data && (
            <>
              <Grid item sm={12} xs={12} xl={12} className="header1">
                <div className="content-1">
                  <img src={background} className="img" />
                  <div className="content">
                    <span className="title">How can we</span>
                    <span className="title pad pad1">help you today?</span>
                    <span className="desc title-light">
                      Browse support articles below to get help, and if you
                      can't find what you are looking for, choose an option to
                      contact an instructional coach.
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                container
                sm={12}
                spacing={3}
                xs={12}
                xl={12}
                className="pt-20 blogs-content-actual"
              >
                {this.state.blogs.map((blog: Blogs, index: number) => {
                  return (
                    <Grid item sm={4} xs={4} className="blogs" key={index}>
                      <span className="title top-title">{blog.title}</span>
                      {blog.blogs
                      ?.sort((a: Content, b: Content) => a.title.localeCompare(b.title)) 
                      .slice(0, 3)
                      .map((data: Content,indexBlogs:number) => (
                        <span
                          className="menu"
                          data-test-id="blogTitle"
                          onClick={() => {            
                            this.setData(index,indexBlogs);
                          }}
                          key={indexBlogs}
                        >
                          {data.title}
                        </span>
                      ))}
                  {
                    blog.blogs.length>3 && 
                    <Grid item sm={4} xs={4} className="seeAll">
                    <Typography data-test-id="seeAll" onClick={()=>this.handleNavigationArtical("Artical",index)}>See all</Typography>  
                      <ArrowRightAltIcon style={{color:"#00649E"}}/>
                     </Grid> 
                  }
                    </Grid>
                  );
                })}
               
              </Grid>
            </>
          )}
         <Footer isSupportContent={false}/>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} />
      </Grid>
    );
  }
}
// Customizable Area End
