import React from "react";
import { Grid, Button } from "@material-ui/core";
import { getGradeCombo, getMinutes, getSteps, getUserDetails } from "../../../../components/src/NativeWebRouteWrapper/Utils";
import { listBack } from "../../../ContentManagement/src/assets";
import { creatorCard3 } from "../assets";
import { setStorageData } from "../../../../framework/src/Utilities";
import {warningIcon} from "../../../ContentManagement/src/assets";

import {
  gradeIcon,
  durationIcon,
  award,
} from "../../../categoriessubcategories/src/assets";
import { openEmailClient } from "../../../../components/src/utility";

export const ProjectBtn = ({ state, addProjectPlan }: any) => {
    const userDetail = getUserDetails();
    const shouldRenderButton =
    !(
      state.setSlectedCard.attributes?.related_classboxes?.owned_classbox &&
      state.setSlectedCard.attributes?.related_classboxes?.owned_classbox !== undefined
    ) && userDetail.school !== null;

  return (
    <>
      {shouldRenderButton && (
        <Button className="btn2" id="btn01" onClick={addProjectPlan}>
          + Plan
        </Button>
      )}
    </>
  );
};

const ProjectDetails = ({ state, navigation, addProjectPlan }: any) => {
  let projectCreator = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const userDetail = getUserDetails();
  return (
    <Grid item container sm={5} className="right-container">
      <div className="header">
        <img
          src={state.setSlectedCard.attributes?.hero_image?.url}
          alt="back-image"
          className="Icon"
          style={{ width: "100%", maxHeight: "310px" }}
        />
        <div className={userDetail.school == null?"credit-header-error":"credit-header"}>
        {userDetail.school == null &&
          <div className="unlinkedTeacher">
          <img src={warningIcon} alt="icon" />
          <div className="unlinkedContent">Connect Account To School to Place Order</div>
          </div>
          }
          <div className={userDetail.school == null?"price-container-redborder":"price-container"}>
            <div className="details">
              <span className="txt1">
                {state.setSlectedCard.attributes?.project_step_number}
              </span>
              <span className="txt2">
                <span className="">{getSteps(state.setSlectedCard.attributes?.project_step_number)}</span>
              </span>
            </div>
            <div style={{ display: "flex" }}>
              {!!state.setSlectedCard.attributes?.related_classboxes && state.setSlectedCard.attributes?.related_classboxes
                ?.owned_classbox !== undefined && state.setSlectedCard.attributes?.related_classboxes
                  ?.owned_classbox === false ? (
                <Button
                  className="btn2"
                  id="btn01"
                  data-test-id="orderclassBtn"
                  onClick={() =>{
                    if (userDetail.school === null){
                    setStorageData("id", state.setSlectedCard.id);
                    setStorageData("mode", "preview");
                    navigation.navigate("ProjectDetails", {
                      id: state.setSlectedCard.attributes?.title.replace(/\s+/g, '-'),
                    });
                  }
                  else{
                    navigation?.navigate("PurchaseClassBox", {
                      ids: state.setSlectedCard.attributes.related_classboxes
                        ?.id,
                    })
                  }}}
                >
                  Order Classbox
                </Button>

              ) : (
                <ProjectBtn state={state} addProjectPlan={addProjectPlan} />
              )}

              {!state.setSlectedCard.attributes?.related_classboxes
                ?.owned_classbox && state.setSlectedCard.attributes?.related_classboxes
                  ?.owned_classbox !== undefined && state.setSlectedCard.attributes?.related_classboxes
                    ?.owned_classbox === false ? (
                <Button
                  className="btn1"
                  id="btn02"
                  onClick={() => {
                    if (userDetail.school === null) {
                      openEmailClient();
                    } else {
                      setStorageData("id", state.setSlectedCard.id);
                      setStorageData("mode", "preview");
                      navigation.navigate("ProjectDetails", {
                        id: state.setSlectedCard.attributes?.title.replace(/\s+/g, '-'),
                      });
                    }
                  }}
                >
                 {userDetail.school===null?"Request School Connection":"Preview Project"}
                </Button>
              ) : (
                <Button
                  className="btn1"
                  id="btn03"
                  onClick={() => {
                    if (userDetail.school === null) {
                      openEmailClient();
                    } else {
                      setStorageData("id", state.setSlectedCard.id);
                      setStorageData("mode", "default");
                      navigation.navigate("ProjectDetails", {
                        id: state.setSlectedCard.attributes?.title.replace(/\s+/g, '-'),
                      });
                    }
                  }}
                >
                  {userDetail.school===null?"Request School Connection":"Open Project"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="card-content-div">
          <span className="tags" >
            {state.setSlectedCard.attributes.subject_tags.join(", ")}
          </span>
        </div>
        <span className="title1">{state.setSlectedCard.attributes?.title}</span>
        <span className="desc">
          {state.setSlectedCard.attributes?.short_description}
        </span>

        <div className="awards">
          <div className="awards-div">
            <img src={gradeIcon} alt="img" className="image-card" />
            <span className="awards-title">Grades</span>
            <div className="grades-div">
              <span className="awards-text">
                {getGradeCombo(state.setSlectedCard.attributes?.grade)}
              </span>
            </div>
          </div>
          <div className="awards-div">
            <img src={durationIcon} alt="img" className="image-card" />
            <span className="awards-title">Duration</span>
            <span className="awards-text">
              {state.setSlectedCard.attributes?.duration} {getMinutes(state.setSlectedCard.attributes?.duration)}
            </span>
          </div>
          {state.setSlectedCard.attributes?.credit_award_available && (
            <div className="awards-div mr-0">
              <img src={award} alt="img" className="image-card" />
              <span className="awards-title">Credit Award</span>
              <span className="awards-text">
                {state.setSlectedCard.attributes?.credit_award}
              </span>
            </div>
          )}
        </div>

        {state.setSlectedCard.attributes?.related_classboxes && (
          <Grid item className="card pointer" sm={12}>
            <img src={listBack} alt="icon" className="backimage" />
            <div className="content">
              <span className="title">
                {state.setSlectedCard.attributes.related_classboxes.title}
              </span>
              <img
                src={
                  state.setSlectedCard.attributes.related_classboxes
                    .emoji_url?.url
                }
                alt="icon"
                className="icon"
              />
            </div>
          </Grid>
        )}
        <Grid item className="card pointer margin-pcc" sm={12}>
          <img src={creatorCard3} alt="icon" className="backimage" />
          <div className="content">
            <div className="contentx">
              <span className="title">Project Creator</span>
              <span className="desc-card">
                {state.setSlectedCard.attributes.admin_data.data.attributes.first_name + " " + state.setSlectedCard.attributes.admin_data.data.attributes.last_name}
              </span>
            </div>
            <img
              src={state.setSlectedCard.attributes.creator_card?.url}
              alt="icon"
              className="icon"
            />
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

export default ProjectDetails;
