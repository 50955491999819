import { createSlice } from "@reduxjs/toolkit";
// @ts-ignore
export const teacherPendingRequestSlice = createSlice({
  name: "TeacherpendingRequestData",
  initialState: {
    value: 0,
  },
  reducers: {
    setTeacherPendingRequest: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setTeacherPendingRequest } = teacherPendingRequestSlice.actions;

export default teacherPendingRequestSlice.reducer;
