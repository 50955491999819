import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface TeacherRequest {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    school_id: number;
    accepted_by: null;
    account: {
      data: {
        id: string;
        type: string;
        attributes: {
          first_name: string;
          last_name: string;
          email: string;
          image: {
            url: string;
          } | null;
        };
      };
    };
  };
}

interface RequestAccordionProps {
  teacherRequestData: TeacherRequest[];
  approveTeacherRequestData: (id: string) => void;
  rejectTeacherRequestData: (id: string) => void;
}

export default function RequestAccordionComponent({
  teacherRequestData,
  approveTeacherRequestData,
  rejectTeacherRequestData,
}: RequestAccordionProps) {
  if (!teacherRequestData || teacherRequestData.length === 0) {
    return null;
  }

  const onApprove = (id: string) => {
    approveTeacherRequestData(id);
  };

  const onReject = (id: any) => {
    rejectTeacherRequestData(id);
  };
  const isSingleRequest = teacherRequestData.length === 1;

  return (
    <div className="accordion-container01">
      {teacherRequestData.map((request) => {
        const teacherData = request.attributes.account.data.attributes;
        
        return (
          <Accordion key={request.id} className="accordion1 according_1" style={styles.according_1}  defaultExpanded={isSingleRequest}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className="summory-content"
              
            >
              <div className="requestProfile" style={styles.requestProfile}>
                <Avatar 
                  src={teacherData.image?.url || ""} 
                  className="avatar-img" 
                />
                <span style={{fontSize:"24px", fontFamily:"gotham-medium", lineHeight:"1.8"}}>
                  &nbsp;&nbsp;{`${teacherData.first_name} ${teacherData.last_name}`}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} className="accordion-body">
                <Grid item container spacing={1} sm={12} className="pr0">
                  <Divider className="divider-1" style={{width:"100%", marginBottom:"25px"}} />
                  <Grid item sm={6}>
                    <div className="card">
                      <div className="request_header">
                        <div>
                          <span className="user_mail" style={styles.user_mail}>User Email:</span>
                        </div>
                        <div className="email" style={styles.email}>
                          <span>{teacherData.email}</span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item container className="buttonContainer" style={styles.buttonContainer} sm={12}>
                    <div className="requestBtn" style={styles.requestBtn}>
                      <Button 
                        className="reject reject-button"
                        style={styles.reject}
                        onClick={() => onReject(request.id)}
                      >
                        Reject Request To Join School 
                      </Button>
                      <Button  
                        className="approve" 
                        style={styles.approve}
                        onClick={() => onApprove(request.id)}
                      >
                        Approve Request To School
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

const styles = {
  requestProfile: {
    display: "flex"
  },
  according_1:{
    marginBottom:"20px"
  },
  user_mail: {
    fontFamily: "Gotham-light",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 325,
    color: "black"
  },
  email: {
    fontFamily: "Gotham",
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 700,
    color: "#344054"
  },
  buttonContainer: {
    width: "100%"
  },
  requestBtn: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0",
    width: "100%",
    backgroundColor: "#F2F4F7",
    gap: "16px"
  },
  reject: {
    borderRadius: "10px",
    padding: "12px 24px 12px 24px",
    fontSize: "16px",
    fontFamily: "Gotham-medium",
    color: "#B42318",
    "&.MuiButton-root:hover": {
      background: "white"
    }
  },
  approve: {
    borderRadius: "10px",
    padding: "12px 24px 12px 24px",
    background: "#007DC6",
    fontSize: "16px",
    fontFamily: "Gotham-medium",
    lineHeight: "24px",
    color: "white"
  }
};