import React from "react";
import {
  Drawer,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";
import { credit } from "../assets";
import ListTransaction from "./ListTransaction";

const TeacherDetailsDrawer = ({
  isDrawerOpen,
  handleDrawer,
  handleReclaimModal,
  handleAddCreditModal,
  state,
  fetchMoreData,
}: any) => {
  return (
    <Drawer
      className="teacher-details-drawer"
      anchor="right"
      open={isDrawerOpen}
      onClose={handleDrawer}
    >
      <DialogTitle disableTypography className="drawerTitle">
        <ListItem button>
          <ListItemAvatar>
            <Avatar src={state.drawerDetails.image} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span className="user-name">{state.drawerDetails.name}</span>
            }
          />
        </ListItem>
        <IconButton onClick={handleDrawer}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <Grid container className="detail_credit_modal">
        <Grid item sm={6} className="credit-details">
          <span className="title">Lifetime Allocated</span>
          <span className="credits">{state.drawerDetails.allocated}</span>
        </Grid>
        <Grid item sm={6} className="credit-details">
          <span className="title">Lifetime Earned</span>
          <span className="credits">{state.drawerDetails.earned}</span>
        </Grid>
        <Divider className="divider" />
        <Grid item sm={12} className="body">
          <span className="title">Current Balance</span>
          <span className="credits">{state.drawerDetails.currentBalance}</span>
          <div className="btn-container">
            <Button className="add-credit" onClick={handleAddCreditModal}>
              <img src={credit} alt="icon" /> &nbsp; Add Credits
            </Button>
            <Button className="reclaim-credit" onClick={handleReclaimModal}>
              Reclaim
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid sm={12} className="title-list">
        <span className="titlt-text">Last Activity</span>
      </Grid>
      <Grid item sm={12} className="transactions-list-container">
        {state.teacherTransactionList.length > 0 && (
          <ListTransaction state={state} fetchMoreData={fetchMoreData} />
        )}
      </Grid>
    </Drawer>
  );
};

export default TeacherDetailsDrawer;
