import { configureStore } from '@reduxjs/toolkit';
import classboxesSlice from '../../components/src/NativeWebRouteWrapper/classboxesSlice';
import pendingRequestSlice from '../../components/src/NativeWebRouteWrapper/pendingRequestSlice';
import teacherPendingRequestSlice from '../../components/src/NativeWebRouteWrapper/teacherPendingRequest';
export default configureStore({
  reducer: { classboxesSlice: classboxesSlice,
    pendingRequestSlice: pendingRequestSlice,
    teacherPendingRequestSlice:teacherPendingRequestSlice
   }
});
