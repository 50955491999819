// Customizable Area Start
import React from "react";
import { Breadcrumbs, Typography, Link } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
export const configJSON = require("./config");
import SupportController, { Blogs, Props } from "./SupportController";
import "../assets/support.scss";
import { articalback} from "./assets";




export default class SupportArtical extends SupportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const getFirstPContent = (htmlContent: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");
        const firstP = doc.querySelector("p");
        return firstP ? firstP.textContent?.slice(0, 100) ?? "" : "";
      };
    const indexNumber = localStorage.getItem("articalIndex");
    const filterData = indexNumber 
      ? this.state.blogs[Number(indexNumber)] 
      : undefined;    
    return (
      <React.Fragment>
        <div className="articalMain">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography data-test-id="support" className="supportbread" onClick={() => this.handleNavigation("Support")}>
            Support
          </Typography>
          ,
          <Typography data-test-id="supportartical" className="supportartical"
          >
            {filterData?.title}
          </Typography>
        </Breadcrumbs>
        <div className="articaldiv">
        <img className="articalimg" src={articalback}></img>
        </div>
        
        {filterData?.blogs?.sort((a, b) => a.title.localeCompare(b.title)).map((blog,index) => (
            <div key={blog.title} className="articalName" onClick={() => this.handleNavigationContentIndex("Content",index)}>
                <Typography className="articaltitle">{blog.title}</Typography>
                <div className="discrption">{getFirstPContent(blog.content)}</div>
            </div>
          ))}
          </div>
      </React.Fragment>
    );
  }
}
// Customizable Area End
